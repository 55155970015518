import React from 'react'
import { graphql } from 'gatsby'
import { MDXRenderer } from 'gatsby-plugin-mdx'
import { Typography } from '@material-ui/core'
import Link from '@components/Link'
// import Bio from '@components/bio'
import Layout from '@components/layout'
import SEO from '@components/seo'
import Bio from '@components/bio'
import { rhythm } from '@utils/typography'
// import GridContainer from '@components/Grid/GridContainer'
// import GridItem from '@components/Grid/GridItem'
import clsx from 'clsx'
import styles from '@assets/jss/views/components.js'
import { makeStyles } from '@material-ui/core/styles'
import Parallax from '@components/Parallax/Parallax'
// import Card from "@components/Card/Card.js"
// import CardBody from "@components/Card/CardBody.js"
// import CardHeader from "@components/Card/CardHeader.js"
import Grid from '@material-ui/core/Grid'
import Image from 'gatsby-image'

const useStyles = makeStyles({
  ...styles,
  wrap: {
    minWidth: '100%',
    minHeight: '480px',
    position: 'relative',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center'
  },
  title: {
    ...styles.container,
    paddingTop: '20px',
    paddingBottom: '30px',
    color: '#ffeedb',
    textAlign: 'center',
    backgroundColor: 'rgba(0, 0, 0, 0.5)',
    position: 'absolute',
    zIndex: '50'
  }
})

const TeacherPostTemplate = (props) => {
  const classes = useStyles()
  const post = props.data.mdx
  const siteTitle = props.data.site.siteMetadata.title
  const { previous, next } = props.pageContext
  const parallax = {
      filter: true,
      image: require('@assets/img/bg0.jpg'),
      style: { minHeight: '480px' },
      children: (
        <div className={classes.wrap}>
          { post.frontmatter.featuredImage && (
              <div style={{ minWidth: "100%", zIndex: '-10' }}>
                <Image
                  fluid={post.frontmatter.featuredImage.childImageSharp.fluid}
                  style={{ minHeight: "480px" }}
                />
              </div>
            )
          }
          <Bio 
            title={post.frontmatter.title}            
            style={{ marginTop: "80px" }}
            avatar={post.frontmatter.avatar || props.data.avatar}
          />
        </div>
      ),
    }
  return (
    <Layout location={props.location} title={siteTitle}>
      <SEO
        title={post.frontmatter.title}
        description={post.frontmatter.description || post.excerpt}
      />
      <Parallax
        {...parallax}
      >
      </Parallax>
      <div className={clsx(classes.main, classes.mainRaised)}  style={{ marginTop: "-80px", backgroundColor: "rgb(255,252,255)" }}>
        <div className={classes.container}>
          <br />
          <hr
            style={{
              marginBottom: rhythm(1),
            }}
          />
          <MDXRenderer>{post.body}</MDXRenderer>
          <hr />          
          <Typography component="p">
            更多教师介绍：
          </Typography>
          <Grid container spacing={2}>
            <Grid item xs={12} md={6} style={{ display: 'flex', justifyContent: "center" }}>
              {previous && (
              <>
                <Link 
                  to={`/${previous.frontmatter.type}${previous.fields.slug}`}
                  rel="prev"
                  style={{ 
                    marginTop: "80px", 
                    textDecoration: "none",
                    boxShadow: "none"
                  }}
                >
                  <Bio
                    small
                    title={previous.frontmatter.title}
                    avatar={previous.frontmatter.avatar || props.data.avatar}
                    style={{ position: "static" }}
                  />
                </Link>
              </>
              )}
            </Grid>
            <Grid item xs={12} md={6} style={{ display: 'flex', justifyContent: "center" }}>
              {next && (
              <>
                <Link 
                  to={`/${next.frontmatter.type}${next.fields.slug}`}
                  rel="next"
                  style={{ 
                    marginTop: "80px", 
                    textDecoration: "none",
                    boxShadow: "none"
                  }}
                >
                  <Bio
                    small
                    title={next.frontmatter.title}
                    avatar={next.frontmatter.avatar || props.data.avatar}
                    style={{ position: "static" }}
                  />
                </Link>
              </>
              )}
            </Grid>
          </Grid>
          <br /><br />
        </div>
      </div>
    </Layout>
  )
}

export default TeacherPostTemplate

export const pageQuery = graphql`
  query TeacherPostBySlug($slug: String!) {
    site {
      siteMetadata {
        title
        author
      }
    }
    avatar: file(absolutePath: { regex: "/avatar.png/" }) {
      childImageSharp {
        fixed(width: 100, height: 100, fit: COVER) {
          ...GatsbyImageSharpFixed
        }
      }
    }
    mdx(fields: { slug: { eq: $slug } }) {
      id
      excerpt(pruneLength: 160)
      body
      frontmatter {
        title
        date(formatString: "MMMM DD, YYYY")
        description
        avatar {
          childImageSharp {
            fixed(width: 200, height: 200, fit: COVER) {
              ...GatsbyImageSharpFixed
            }
          }
        }
        featuredImage {
          childImageSharp {
            fluid {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    }
  }
`
